import React, { useState } from 'react'
import { FaBirthdayCake, FaEnvelope, FaMailBulk, FaPhone, FaPlaneDeparture, FaQuoteLeft, FaUser, FaUserGraduate } from 'react-icons/fa'
import Button from '../Button'
import Data from '../../StaticData/Admission/Admission.json'
import DropDown from '../DropDown'
import CourseData from '../../StaticData/Courses/Course.json'
import ErrorMessage from '../ErrorMessage'
import SendEmail from '../SendEmail'
const current = new Date();
const dates = current.getFullYear() + '-' + (((current.getMonth() + 1) < 10) ? "0" : "") + (current.getMonth() + 1) + '-' + ((current.getDate() < 10) ? "0" : "") + current.getDate()

const Model = {
    Name: "",
    FatherName: "",
    MobileNumber: "",
    EmailAddress: "",
    Qualification: "Select...",
    Course: "Select...",
    DateOfBirth: dates,
    Address: ""
}


function AddMission({ setopen }) {
    const [values, setValue] = useState(Model);
    const [error, setError] = useState(null);
    const HandleOnChange = (e) => {
        const { value, name } = e.target;
        setValue({
            ...values,
            [name]: value
        })
    }

    const submitData = e => {
        e.preventDefault();
        console.log(values)
        if ((values.Name.toString().trim() !== "") && (values.FatherName.toString().trim() !== "") && (values.Address.toString().trim() !== "") && (values.Course.toString().trim() !== "") && (values.Qualification.toString().trim() !== "") && (values.EmailAddress.toString().trim() !== "")) {
            const EmailCheck = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (EmailCheck.test((values.EmailAddress))) {
                const MobileCheck = /^[0-9]{3}-[0-9]{7}$/;
                if (MobileCheck.test(values.MobileNumber)) {
                    const Subject = `Getting the course of ${values.Course}`;
                    const Message = `Address ${values.Address} phoneNumber ${values.MobileNumber}`
                    SendEmail(values.EmailAddress,Subject , Message);
                    setValue(Model);
                    setopen(true);
                    setError(null);
                } else {
                    setError("Mobile Number is not valid...!");
                }
            }
            else {
                setError("Email Address is not valid...!");
            }
        } else {
            setError("All Fields Are Required");
        }
    }


    return (
        <div className='admission-container container-center'>
            <div className="form-row">
                <div className='form-wrapper' style={{textAlign: 'center' , boxShadow: 'none'}}>
                    {/* {(error) && <ErrorMessage Message={error} />}
                    <form onSubmit={submitData}>
                        <div className='fields'>
                            <div className='field'>
                                <div className='head'>
                                    <FaUser /> First Name
                                </div>
                                <input onChange={HandleOnChange} type="text" value={values.Name} name='Name' placeholder='Name' />
                            </div>
                            <div className='field'>
                                <div className='head'>
                                    <FaUser /> Father Name
                                </div>
                                <input onChange={HandleOnChange} type="text" value={values.FatherName} name='FatherName' placeholder='Father Name' />
                            </div>
                        </div>
                        <div className='fields'>
                            <div className='field'>
                                <div className='head'>
                                    <FaPhone /> Mobile Number
                                </div>
                                <input onChange={HandleOnChange} type="text" value={values.MobileNumber} name='MobileNumber' placeholder='000-0000000' />
                            </div>
                            <div className='field'>
                                <div className='head'>
                                    <FaUserGraduate /> Qualifation
                                </div>
                                <DropDown options={Data.qualification} onChange={(value) => setValue({ ...values, Qualification: value.value })} placeHolder={values.Qualification} />
                            </div>
                        </div>
                        <div className='fields'>
                            <div className='field'>
                                <div className='head'>
                                    <FaPlaneDeparture /> Courses
                                </div>
                                <DropDown options={CourseData.Cards.map((item) => ({ "value": item.title, "label": item.title }))} onChange={(value) => setValue({ ...values, Course: value.value })} placeHolder={values.Course} />
                            </div>
                            <div className='field'>
                                <div className='head'>
                                    <FaBirthdayCake /> Data of Birth
                                </div>
                                <input onChange={HandleOnChange} value={values.DateOfBirth} name='DateOfBirth' type="date" id="start" />
                            </div>
                        </div>
                        <div className='field'>
                            <div className='head'>
                                <FaMailBulk /> Email Address
                            </div>
                            <input value={values.email} onChange={HandleOnChange} name='EmailAddress' type="email" placeholder='example@gmail.com' />
                        </div>
                        <div className='field'>
                            <div className='head'>
                                <FaMailBulk />  Address
                            </div>
                            <input onChange={HandleOnChange} value={values.Address} name='Address' type="text" placeholder='Address' />
                        </div>
                        <div className='field'>
                            <Button>Send Message</Button>
                        </div>
                    </form> */}


                    <a style={{padding: '10px'  , textAlign: 'center' , background: "#28348F", borderRadius: '5px' , textDecoration: 'none' , color: 'white'}} href="https://docs.google.com/forms/d/e/1FAIpQLScTz1b2gxDgEPFrP3iaA06yLWc8PZt-vQK-CYaanvN9lqaiTA/viewform?usp=sf_link" target='_blank'>Apply for Admission</a>
                </div>
                <div className='form-details'>
                    <h3>{Data.title}</h3>
                    <p>{Data.description}</p>
                    {
                        Data.childs.map((item) => (
                            <div className='details-card'>
                                <span>{item.name}</span>
                                <p>{item.description}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default AddMission
