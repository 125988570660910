import React from 'react'

function SucessfullyPopup({open , setopen , title , description}) {
    return (
        <div class={(open) ? 'message comein' : 'message'} >
            <div class={(open) ? 'check scaledown' : 'check'}>
                &#10004;
            </div>
            <p>
            {title}
            </p>
            <p>
            {description}
            </p>
            <button onClick={() => setopen(false)}>
                OK
            </button>
        </div>
    )
}

export default SucessfullyPopup
