import React from 'react'
import data from '../../StaticData/About/AboutCard'
import Card from '../../Componets/AboutCard'
function AboutCard() {
  return (
    <div className='About-card-container container-center'>
        <div className='row'>
            {
                data.map((item) => (
                  <Card item={item} /> 
                ))
            }
        </div>
    </div>
  )
}

export default AboutCard
