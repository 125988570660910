import React from 'react'
import { useLocation } from 'react-router-dom'
import Footer from '../Componets/Layout/Footer'
import Navbar from '../Componets/Layout/Navbar'
import Data from '../StaticData/Blog/Blog.json'
import Subscribe from '../Componets/Subscribe'
import Banner from '../Componets/Banner'
import SingleAbout from '../Componets/Blog/SingleAbout'
import MultiAbout from '../Componets/Blog/MultiAbout'
import Loading from '../Componets/Loading'
import Empty from '../Componets/Empty'
function SingleBlog() {

    const location = useLocation();

    const filterdCardData = Data.Cards.filter(x => x.id === Number(location.search.split("=")[1]));

    return (
        <div className='container'>
            <div className='nav'>
                <Navbar />
            </div>
            <Loading />
            <Banner heading={Data.heading} image={Data.image} />
            {
                (filterdCardData.length > 0) ?
                    <>
                        <SingleAbout card_data={filterdCardData} />
                        {
                            filterdCardData[0].MultiAbout.map((item) => (
                                <MultiAbout item={item} />
                            ))
                        }
                    </>
                    : <Empty title={"Sorry We Could Not Find This Blog...!"} />
            }

            <Subscribe />
            <Footer />
        </div>
    )
}

export default SingleBlog
