import React from 'react'
import Data from '../../StaticData/About/About.json'
function Gallery() {
  return (
    <div className='galley-container container-center'>
        <div className='Gallery'>
            <h3>Gallery</h3>
            <div className='Gallery-row'>
                {
                   Data.Gallery.map((item) => (
                        <div className='Gallery-image'>
                            <img src={process.env.PUBLIC_URL + '/Assets/'+ item} />
                         </div>
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default Gallery
