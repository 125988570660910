import React from 'react'
import Banner from '../Componets/Banner'
import Footer from '../Componets/Layout/Footer'
import Navbar from '../Componets/Layout/Navbar'
import Data from '../StaticData/Team/Team.json'
import CardGrid from '../Componets/Team/card-grid'
import Subscribe from '../Componets/Subscribe'
import Loading from '../Componets/Loading'
function Team() {
    return (
        <div className='container'>
            <div className='nav'>
                <Navbar />
            </div>
            <Loading />
            <Banner heading={Data.heading} image={"Banner3.jpg"} />
            <CardGrid Data={Data} />
            <Subscribe />
            <Footer />
        </div>
    )
}

export default Team
