import React from 'react'

function Empty({title}) {
  return (
    <div className='Empty-box'>
        <img src={process.env.PUBLIC_URL + "/Assets/empty-box.png"} />
        <span>{title}</span>
    </div>
  )
}

export default Empty
