import React, { useEffect, useState } from 'react'

function Loading() {

    // Set loading state to true initially
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
          }, 200);
    }, [])

    // If page is in loading state, display 
    // loading message. Modify it as per your 
    // requirement.
        return ( 
        <div className={(loading === true) ?  "FullViewLoading Active" :  "FullViewLoading"  }>
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
        )


}

export default Loading
