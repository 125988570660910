function SendEmail(Email ,Subject ,  Body) {
    const config = {
        Host: process.env.REACT_APP_EMAIL_HOST,
        Port: process.env.REACT_APP_EMAIL_PORT,
        Username: process.env.REACT_APP_EMAIL_USER_NAME,
        Password: process.env.REACT_APP_EMAIL_PASSWORD,
        To: process.env.REACT_APP_EMAIL_TO,
        From: Email,
        Subject: Subject,
        Body: Body
    }
    window.Email.send(config);
}

export default SendEmail
