import React from 'react'
import { FaClock, FaSearchLocation, FaTimesCircle, FaUser } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import Button from './Button'

function CarrerCard({ item }) {
    const navigation = useNavigate();
    return (
        <div className='card'>
            <div className='wrapper'>
                <div className='About'>
                    <h3>{item.title}</h3>
                    <p>{item.Description}</p>
                    <Button onClick={() => navigation('/AppliedPosition?id='+item.id)}>Apply Now</Button>
                </div>
                <div className='details'>
                    <div className='details-col'>
                        <div className='icon'>
                            <div className='circle'>
                                <FaSearchLocation />
                            </div>
                        </div>
                        <div className='icon-details'>
                            <span>Location</span>
                            <p>{item.location}</p>
                        </div>
                    </div>
                    <div className='details-col'>
                        <div className='icon'>
                            <div className='circle'>
                                <FaUser />
                            </div>
                        </div>
                        <div className='icon-details'>
                            <span>Experience</span>
                            <p>{item.Experience}</p>
                        </div>
                    </div>
                    <div className='details-col'>
                        <div className='icon'>
                            <div className='circle'>
                                <FaClock />
                            </div>
                        </div>
                        <div className='icon-details'>
                            <span>Working Hours</span>
                            <p>{item.WorkingHours}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CarrerCard
