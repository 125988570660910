import React, { useState } from 'react'
import Banner from '../Componets/Banner'
import Cardgrid from '../Componets/Blog/card-grid'
import Tools from '../Componets/Blog/Tools'
import Footer from '../Componets/Layout/Footer'
import Navbar from '../Componets/Layout/Navbar'
import Loading from '../Componets/Loading'
import Pagination from '../Componets/Pagination'
import Subscribe from '../Componets/Subscribe'
import Data from '../StaticData/Blog/Blog.json'

const FilterModel = {
    categerous: "",
    tags: "",
    text: ""
}

function Blog() {
    // User is currently on this page
    const [currentPage, setCurrentPage] = useState(1);
    // No of Records to be displayed on each page   
    const [recordsPerPage, setrecordsPerPage] = useState(2);
    // Filter Values Statute
    const [FilterData, setFilterData] = useState(FilterModel);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

    let currentRecords = [];
    // Records to be displayed on the current page
    currentRecords = Data.Cards.slice(indexOfFirstRecord,
        indexOfLastRecord)

    let nPages = 0;
    nPages = Math.ceil(Data.Cards.length / recordsPerPage);

    const TagsonChange = item => {
        if (FilterData.tags !== item) {
            setFilterData({
                ...FilterData,
                tags: item
            })
        } else {
            setFilterData({
                ...FilterData,
                tags: ""
            })
        }
    }

    const categouresonChange = item => {
        if (FilterData.categerous !== item) {
            setFilterData({
                ...FilterData,
                categerous: item
            })
        } else {
            setFilterData({
                ...FilterData,
                categerous: ""
            })
        }
    }

    const textonChange = e => {
        setFilterData({
            ...FilterData,
            text: e.target.value
        })
    }

    if (FilterData.text !== "") {
        currentRecords = Data.Cards.filter(x => {
            return (
                x.title.toLowerCase().indexOf(FilterData.text.toString().toLowerCase()) !== -1
            )
        }).slice(indexOfFirstRecord,
            indexOfLastRecord)
        nPages = Math.ceil(Data.Cards.filter(x => {
            return (
                x.title.toLowerCase().indexOf(FilterData.text.toString().toLowerCase()) !== -1
            )
        }).length / recordsPerPage);
    }

    if (FilterData.categerous !== "") {
        currentRecords = currentRecords.filter(x => {
            return (
                x.categerous.toLowerCase().indexOf(FilterData.categerous.toString().toLowerCase()) !== -1
            )
        }).slice(indexOfFirstRecord,
            indexOfLastRecord)
            nPages = Math.ceil(currentRecords.length / recordsPerPage);
    }

    if (FilterData.categerous !== "") {
        currentRecords = currentRecords.filter(x => {
            return (
                x.tag.toLowerCase().indexOf(FilterData.tags.toString().toLowerCase()) !== -1
            )
        }).slice(indexOfFirstRecord,
            indexOfLastRecord)
            nPages = Math.ceil(currentRecords.length / recordsPerPage);
    }




    return (
        <div className='container'>
            <div className='nav'>
                <Navbar />
            </div>
            <Loading />
            <Banner heading={Data.heading} image={Data.image} />
            <div className='blog-container container-center'>
                <div className='wrapper'>
                    <div className='cardView'>
                        <Cardgrid Data={currentRecords} />
                        {(currentRecords.length > 0) ?
                            <Pagination
                                nPages={nPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                            : ""}
                    </div>
                    <div className='ToolsView'>
                        <Tools FilterData={FilterData} textonChange={textonChange} TagsonChange={TagsonChange} categouresonChange={categouresonChange} Data={Data} />
                    </div>
                </div>
            </div>

            <Subscribe />
            <Footer />
        </div>
    )
}

export default Blog
