import { FaCode, FaGlobe, FaMicrosoft, FaNetworkWired } from "react-icons/fa"

const data = [
    {
        "id": 1,    
        "icon": <FaMicrosoft />,
        "title": "Office Automation",
        "description":  "MS Word , Advance Excell , Powerpoint , Publisher , Access , Outlook"
    },
    {
        "id": 3,
        "icon": <FaCode />,
        "title": "Programing Languages",
        "description":  "C , C++ , C Sharp , Html , Css , Javascript , PHP , MySql , MS SQL"
    },
    {
        "id": 3,
        "icon": <FaNetworkWired />,
        "title": "OS & Networking",
        "description":  "Microsft windows , Linux , Basic Computer Networking , Cisco Packet Tracer"
    }
]

export default data