import React from 'react'
import { FaCertificate, FaClipboard, FaClock, FaList, FaUser, FaUserGraduate } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import Button from './Button'

function CourseBanner({ Data, CardData, teacher_data }) {
    const Navigation = useNavigate();
    return (
        <div className='banner-container course'>
            <div className='banner-image' style={{ backgroundImage: "url(/Assets/" + Data.banner + ")" }}>
                <div className='container-center'>
                <h3>{CardData[0].title}</h3>
                <div className='highlighted_details'>
                    <div className='fields'>
                        <div className='imgcircle'>
                            <img src={process.env.PUBLIC_URL + '/Assets/' + teacher_data[0].image} />
                        </div>
                        {teacher_data[0].name}
                    </div>
                    <div className='fields'>
                        <FaClipboard />
                        Lessons  {CardData[0].lessons}
                    </div>
                    {(CardData[0].Certificate) &&
                        <div className='fields'>
                            <FaCertificate />
                            Certificate
                        </div>
                    }
                </div>

                <div className='some-details'>
                    <div className='fields'>
                        <FaUserGraduate />
                        Students {CardData[0].students}
                    </div>
                    <div className='fields'>
                        <FaClock />
                        Duration {CardData[0].duration}
                    </div>
                </div>

                <div className='action-call'>
                    <div className='fields'>
                        {CardData[0].Amount} <span>RS</span> {CardData[0].AmountText}
                    </div>
                    {/* <div className='fields'>
                        <Button onClick={() => Navigation({pathname: `Admission?id=${CardData[0].id}`})} >Take Course</Button>
                    </div> */}
                </div>
            </div>
            </div>
        </div>
    )
}

export default CourseBanner
