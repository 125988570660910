import React from 'react'
import { FaBuilding } from 'react-icons/fa'
import CarrerCard from '../CarrerCard'

function CardGrid({Data}) {
  return (
    <div className='carrer-container'>
        <div className='wrapper container-center'>
            <div className='heading'>
                <FaBuilding /> {Data.title}
            </div>
            <div className='card-container'>
                {
                    Data.cards.map((item) => (
                        <CarrerCard item={item} />
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default CardGrid
