import React from 'react'

function MultiAbout({ item }) {
    return (
        <div className='multi-about-container container-center'>
            <div className='row' style={{ flexDirection: (item.FirstImage) ? "row-reverse" : "initial" }}>
                <div className='details' style={(item.FirstImage) ? {paddingLeft: "25px"} : {}}>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                </div>
                <div className='image'>
                    <img src={process.env.PUBLIC_URL + "/Assets/" + item.image} />
                </div>
            </div>
        </div>
    )
}

export default MultiAbout
