import React, { useEffect, useState } from 'react'
import Footer from '../Componets/Layout/Footer'
import Navbar from '../Componets/Layout/Navbar'
import Data from '../StaticData/Courses/Course.json'
import Banner from '../Componets/Banner'
import CardGrid from '../Componets/Courses/Card-grid'
import FilterAndSearch from '../Componets/Courses/Filter-Search-title'
import Pagination from '../Componets/Pagination'
import Subscribe from '../Componets/Subscribe'
import Loading from '../Componets/Loading'

const FilterInput = {
    ASC: false,
    DESC: false,
    LOWEST: false,
    HIGHEST: false
}


function Courses() {
    // User is currently on this page
    const [currentPage, setCurrentPage] = useState(1);
    // No of Records to be displayed on each page   
    const [recordsPerPage, setrecordsPerPage] = useState(6);
    ///Search with names
    const [searchData, setsearchData] = useState("");
    //Filter Input State
    const [FilterBtn, setFilterBtn] = useState(FilterInput);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

    const FilterBtnOnChange = e => {
        if (e.target.checked) {
            if (e.target.name === "ASC") {
                setFilterBtn({
                    ...FilterBtn,
                    ASC: true,
                    DESC: false,
                })
            }
            if (e.target.name === "DESC") {
                setFilterBtn({
                    ...FilterBtn,
                    ASC: false,
                    DESC: true,
                })
            }

            if (e.target.name === "LOWEST") {
                setFilterBtn({
                    ...FilterBtn,
                    LOWEST: true,
                    HIGHEST: false,
                })
            }
            if (e.target.name === "HIGHEST") {
                setFilterBtn({
                    ...FilterBtn,
                    LOWEST: false,
                    HIGHEST: true,
                })
            }
        }
    }

    let currentRecords = [];
    // Records to be displayed on the current page
    currentRecords = Data.Cards.slice(indexOfFirstRecord,
        indexOfLastRecord)
    let nPages = 0;

    nPages = Math.ceil(Data.Cards.length / recordsPerPage);


    if (FilterBtn.ASC) {
        currentRecords = Data.Cards.sort(function (a, b) {
            if (a.title < b.title) { return -1; }
            if (a.title > b.title) { return 1; }
            return 0;
        }).slice(indexOfFirstRecord,
            indexOfLastRecord)
    }

    if (FilterBtn.DESC) {
        currentRecords = Data.Cards.sort(function (a, b) {
            if (a.title > b.title) { return -1; }
            if (a.title < b.title) { return 1; }
            return 0;
        }).slice(indexOfFirstRecord,
            indexOfLastRecord)
    }

    if (FilterBtn.LOWEST) {
        currentRecords = Data.Cards.filter(x => {
            return (
                x.tag.toLowerCase().indexOf("LOWEST".toString().toLowerCase()) !== -1
            )
        }).slice(indexOfFirstRecord,
            indexOfLastRecord)
        nPages = Math.ceil(Data.Cards.filter(x => {
            return (
                x.tag.toLowerCase().indexOf("LOWEST".toString().toLowerCase()) !== -1
            )
        }).length / recordsPerPage);
    }

    if (FilterBtn.HIGHEST) {
        currentRecords = Data.Cards.filter(x => {
            return (
                x.tag.toLowerCase().indexOf("HIGHEST".toString().toLowerCase()) !== -1
            )
        }).slice(indexOfFirstRecord,
            indexOfLastRecord)
        nPages = Math.ceil(Data.Cards.filter(x => {
            return (
                x.tag.toLowerCase().indexOf("HIGHEST".toString().toLowerCase()) !== -1
            )
        }).length / recordsPerPage);
    }

    if (searchData !== "") {
        currentRecords = Data.Cards.filter(x => {
            return (
                x.title.toLowerCase().indexOf(searchData.toString().toLowerCase()) !== -1 ||
                x.tag.toLowerCase().indexOf(searchData.toString().toLowerCase()) !== -1
            )
        }).slice(indexOfFirstRecord,
            indexOfLastRecord)
        nPages = Math.ceil(Data.Cards.filter(x => {
            return (
                x.title.toLowerCase().indexOf(searchData.toString().toLowerCase()) !== -1 ||
                x.tag.toLowerCase().indexOf(searchData.toString().toLowerCase()) !== -1
            )
        }).length / recordsPerPage);
    }

    const ClearEffect = () => {
        setFilterBtn(FilterInput)
    }



    return (
        <div className='container'>
            <div className='nav'>
                <Navbar />
            </div>
            <Loading />
            <Banner image={Data.banner} heading={Data.heading} />
            <FilterAndSearch ClearEffect={ClearEffect} setsearchData={setsearchData} FilterBtn={FilterBtn} FilterBtnOnChange={FilterBtnOnChange} />
            <CardGrid Data={currentRecords} />
            {(currentRecords.length > 0) ?
                <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
                : ""}
            <Subscribe />
            <Footer />
        </div>
    )
}

export default Courses
