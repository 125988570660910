import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Banner from '../Componets/Banner'
import PositionForm from '../Componets/Carrer/PoitionForm'
import Empty from '../Componets/Empty'
import Footer from '../Componets/Layout/Footer'
import Navbar from '../Componets/Layout/Navbar'
import Loading from '../Componets/Loading'
import Subscribe from '../Componets/Subscribe'
import SucessfullyPopup from '../Componets/SucessfullyPopup'
import Data from '../StaticData/Carrer/Carrer.json'


function AppliedPosition() {
  const [open , setopen] = useState(false);
  const location = useLocation();

  const filterdCardData = Data.cards.filter(x => x.id === Number(location.search.split("=")[1]));


  return (
    <div className='container'>
      <div className='nav'>
        <Navbar />
      </div>
      <Loading />
      <SucessfullyPopup title={"Success"} description="We Will Contact You Via Email. We'll see you soon!" open={open} setopen={setopen} />
 
      {
          (filterdCardData.length > 0) ?
          <>
                <Banner heading={"Applied For Position Of " + filterdCardData[0].title} image={Data.banner} />
      <PositionForm cardData={filterdCardData} setopen={setopen} />
          </>
          : <>     <div className='spacer'></div><Empty title={"Sorry We Could Not Find This Jop...!"} /></>
      }

      <Subscribe />
      <Footer />
    </div>
  )
}

export default AppliedPosition
