import React  , {useState} from 'react'
import AddMission from '../Componets/Addmission/AddMission'
import Banner from '../Componets/Banner'
import Footer from '../Componets/Layout/Footer'
import Navbar from '../Componets/Layout/Navbar'
import Loading from '../Componets/Loading'
import Subscribe from '../Componets/Subscribe'
import SucessfullyPopup from '../Componets/SucessfullyPopup'
import Data from '../StaticData/Admission/Admission.json'
function Admissions() {
    const [open , setopen] = useState(false);
    return (
        <div className='container'>
            <div className='nav'>
                <Navbar />
            </div>
            <Loading />
            <SucessfullyPopup title={"Success"} description="We Will Contact You Via Email. We'll see you soon!" open={open} setopen={setopen} />

            <Banner heading={Data.heading} image={Data.banner} />
            <AddMission setopen={setopen} />
            <Subscribe />
            <Footer />
        </div>
    )
}

export default Admissions
