import React, { useRef, useState } from 'react'
import { FaEnvelope, FaFacebookMessenger, FaMailBulk, FaQuoteLeft, FaUser } from 'react-icons/fa'
import Button from '../Button'
import emailjs from '@emailjs/browser';
import SendEmail from '../SendEmail';

const Model = {
    first_name: "",
    last_name: "",
    email_address: "",
    subject: "",
    message: "",
}

function ContactForm({ Data, setopen }) {

    const [values , setValues] = useState(Model);
   const onChangeFunction =  e => {
        const {name , value} = e.target;
        setValues({
            ...values,
            [name]: value
        })
    }


    const HandleSubmit = (e) => {
        e.preventDefault();
        SendEmail(values.email_address , values.subject ,  values.message);
        setValues(Model);
        setopen(true);
    }
    return (
        <div className='form-container container-center'>
            <div className="form-row">
                <div className='form-wrapper'>
                    <form  onSubmit={HandleSubmit}>
                        <div className='fields'>
                            <div className='field'>
                                <div className='head'>
                                    <FaUser /> First Name
                                </div>
                                <input onChange={onChangeFunction} name='first_name' value={values.first_name} type="text" placeholder='Json' required />
                            </div>
                            <div className='field'>
                                <div className='head'>
                                    <FaUser /> last Name
                                </div>
                                <input onChange={onChangeFunction} name='last_name' value={values.last_name} type="text" placeholder='End' required />
                            </div>
                        </div>
                        <div className='field'>
                            <div className='head'>
                                <FaMailBulk /> Email Address
                            </div>
                            <input onChange={onChangeFunction} name="email_address"  value={values.email_address} type="email" placeholder='example@gmail.com' required />
                        </div>
                        <div className='field'>
                            <div className='head'>
                                <FaQuoteLeft /> Subject
                            </div>
                            <input onChange={onChangeFunction} type="text" name="subject" value={values.subject} placeholder='Subject' required />
                        </div>
                        <div className='field'>
                            <div className='head'>
                                <FaEnvelope /> Message
                            </div>
                            <textarea onChange={onChangeFunction} name='message' value={values.message} placeholder='Message' required ></textarea>
                        </div>
                        <div className='field'>
                            <Button>Send Message</Button>
                        </div>
                    </form>
                </div>
                <div className='form-details'>
                    <h3>{Data.title}</h3>
                    <p>{Data.description}</p>
                    {
                        Data.childs.map((item) => (
                            <div className='details-card'>
                                <span>{item.name}</span>
                                <p>{item.description}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default ContactForm
