import React from 'react'
import {FaBookmark} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import Data from '../../StaticData/Courses/Course.json'
import Button from '../Button'
import CourseCard from '../CourseCard'
function Courses() {
  const navigation = useNavigate();
  return (
    <div className='course-container container-center'>
        <div className='heading'>
            <FaBookmark /> {Data.heading}
        </div>
        <div className='card-container'>
            {
              Data.Cards.slice(0 , 6).map((item , index) => (
                <CourseCard item={item} />
              ))
            }
        </div>
        {
           (Data.Cards.length > 6) && 
           <div className='ReadButtom'>
              <Button onClick={() => navigation("/Courses")}>Read More</Button>
           </div>
        }
      
    </div>
  )
}

export default Courses
