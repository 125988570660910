import React from 'react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'

function Pagination({ nPages, currentPage, setCurrentPage }) {

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
    const nextPage = () => {
        if (currentPage !== nPages)
            setCurrentPage(currentPage + 1)
    }
    const prevPage = () => {
        if (currentPage !== 1)
            setCurrentPage(currentPage - 1)
    }
    return (
        <div className='pagination-container container-center'>
            <ul>
                <li>
                    <button className='btn'  onClick={() => prevPage()}><FaArrowLeft /></button>
                </li>
                {
                    pageNumbers.map(pgNumber => (
                        <li key={pgNumber} className={currentPage == pgNumber ? "active" : ""}>
                            <button onClick={() => setCurrentPage(pgNumber)}>{pgNumber}</button>
                        </li>
                    ))
                }
                <li>
                    <button  className='btn' onClick={() => nextPage()}><FaArrowRight /></button>
                </li>
            </ul>
        </div>
    )
}

export default Pagination
