import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Home from './Home'
import Spiner from '../Componets/Spiner'

// Big Screen Css
import '../Style/BigScreenStyle.css'

// Small Screen css
import '../Style/SmallScreenStyle.css'
import Courses from './Courses'
import Carrer from './Carrer'
import Team from './Team'
import Blog from './Blog'
import ContactUs from './ContactUs'
import CourseSingle from './SingleCourse'
import SingleBlogs from './SingleBlogs'
import About from './About'
import Admissions from './Admissions'
import AppliedPosition from './AppliedPosition'
import NotFound from '../Componets/NotFound'

const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />
    },
    {
      path: "Courses",
      element: <Courses />
    },
    {
      path: "Carrer",
      element: <Carrer />
    }
    ,
    {
      path: "Team",
      element: <Team />
    },
    {
      path: "Blogs",
      element: <Blog />
    },
    {
      path: "contactus",
      element: <ContactUs />
    },
    {
      path: "/course/signle",
      element: <CourseSingle />
    },
    {
      path: "/Blogs/single",
      element: <SingleBlogs />
    },
    {
      path: "/about",
      element: <About />
    },
    {
      path: "/Admission",
      element: <Admissions />
    },
    {
      path: "/AppliedPosition",
      element: <AppliedPosition />
    },
    {
      path: "*",
      element: <NotFound />
    },
    
])


function App() {


  return (
     <RouterProvider router={router} fallbackElement={<Spiner />} />
  )
}

export default App
