import React, { useEffect } from 'react'
import { FaNewspaper } from 'react-icons/fa'
import { Swiper, SwiperSlide } from "swiper/react";
import Data from '../../StaticData/Blog/Blog.json'
import BlogCard from '../BlogCard'
import SwiperCore, { Navigation, Pagination, Autoplay, Scrollbar } from "swiper";
import "swiper/swiper-bundle.min.css";
SwiperCore.use([Navigation, Pagination, Autoplay, Scrollbar]);

function Blogs() {

    return (
        <div className='blog-container '>
            <div className='heading'>
                <FaNewspaper /> {Data.heading}
            </div>
            <div className='Blogcard-container'>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    speed={500}
                    loop={true}
                    touchRatio={1.5}
                    navigation={true}
                    effect={"flip"}
                    pagination={{ clickable: true }}
                    className="mySwiper"
                > 
                        {
                            Data.Cards.map((item) => (
                                <SwiperSlide>
                                    <BlogCard item={item} />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>

            </div >
        </div >
    )
}

export default Blogs
