import React from 'react'
import { useNavigate } from 'react-router-dom'
import Data from '../../StaticData/About/About.json'
import Button from '../Button'
function About() {
    const navigation= useNavigate();
    return (
        <div className='About-container container-center'>
            <div className='About-details'>
            <div className='Heading'>
                <h3 className='undline_heading'>{Data.heading}</h3>
            </div>
            <div className='Details-container'>
                {/* <h4>{Data.title}</h4> */}
                <p>{Data.Description}</p>
                <Button onClick={() => navigation("/about")}>Read More</Button>
            </div>
            </div>
            <div className='About-image'>
                <div className='image-wrapper'>
                    <img src={process.env.PUBLIC_URL + '/Assets/' +  Data.instituate_image} />
                </div>
            </div>
        </div>
    )
}

export default About
