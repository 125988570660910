import React, { useState } from 'react'
import { FaClock, FaEnvelope, FaEnvelopeOpen, FaMailBulk, FaPhone, FaQuoteLeft, FaUser } from 'react-icons/fa'
import Button from '../Button'
import Data from '../../StaticData/Carrer/Carrer.json'
import SendEmailFile from '../SendEmailFile'
import ErrorMessage from '../ErrorMessage'

const Model = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    CoverLetter: "",
    Resume: ""
}


function PositionForm({ cardData, setopen }) {

    const [values, setvalues] = useState(Model);
    const [b64code, setb64code] = useState("");
    const [error, setError] = useState(null);
    const handleChange = e => {
        const { name, value } = e.target;
        setvalues({
            ...values,
            [name]: value
        })
    }

    function GetBase64(file) {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            setb64code(encoded);
        };
        return b64code
    }



    const onSubmit = e => {
        e.preventDefault();
        const file = document.querySelector('#myfile').files[0];
        if ((values.first_name !== "") && (values.email !== "") && (values.last_name !== "") && (values.phone !== "") && (values.CoverLetter !== "")) {
            const EmailCheck = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (EmailCheck.test((values.email))) {
                const MobileCheck = /^[0-9]{3}-[0-9]{7}$/;
                if (MobileCheck.test(values.phone)) {
                    const base64String = GetBase64(file);
                    let Subject = `Applied Position For ${cardData[0].title}`
                    let Message = `<div>${values.first_name} ${values.phone} ${values.CoverLetter}<div/>`
                    SendEmailFile(Subject, Message, base64String, file, values.email);
                    setvalues(Model);
                    setopen(true);
                }else{
                    setError("Phone is not valid...!");
                }
            } else {
                setError("Email is not valid...!");
            }
        } else {
            setError("All Fields Are Required...!");
        }
    }

    return (
        <div className='admission-container container-center'>
            <div className="form-row">
                <div className='form-wrapper'>
                    {(error) && <ErrorMessage Message={error} />}

                    <form onSubmit={onSubmit}>
                        <div className='fields'>
                            <div className='field'>
                                <div className='head'>
                                    <FaUser /> First Name
                                </div>
                                <input value={values.first_name} onChange={handleChange} name='first_name' type="text" placeholder='Json' />
                            </div>
                            <div className='field'>
                                <div className='head'>
                                    <FaUser /> last Name
                                </div>
                                <input value={values.last_name} onChange={handleChange} name='last_name' type="text" placeholder='End' />
                            </div>
                        </div>
                        <div className='fields'>
                            <div className='field'>
                                <div className='head'>
                                    <FaMailBulk /> Email Address
                                </div>
                                <input value={values.email} onChange={handleChange} name='email' type="email" placeholder='example@gmail.com' />
                            </div>
                            <div className='field'>
                                <div className='head'>
                                    <FaPhone /> Phone
                                </div>
                                <input value={values.phone} onChange={handleChange} name="phone" type="text" placeholder='000-0000000' />
                            </div>
                        </div>
                        <div className='field'>
                            <div className='head'>
                                <FaEnvelope /> Cover Letter
                            </div>
                            <textarea value={values.CoverLetter} onChange={handleChange} name="CoverLetter" placeholder='Message'  ></textarea>
                        </div>

                        <div className='field'>
                            <div className='head'>
                                <FaEnvelope /> Resume
                            </div>
                            <input id="myfile" type="file" />
                        </div>
                        <div className='field'>
                            <Button>Send Request</Button>
                        </div>
                    </form>
                </div>
                <div className='form-details'>
                    <h3>{Data.form_title}</h3>
                    <p>{Data.description}</p>
                    {
                        Data.childs.map((item) => (
                            <div className='details-card'>
                                <span>{item.name}</span>
                                <p>{item.description}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default PositionForm
