import React from 'react'
import Banner from '../Componets/Banner'
import Footer from '../Componets/Layout/Footer'
import Navbar from '../Componets/Layout/Navbar'
import Data from '../StaticData/About/About.json'
import AboutSecton from '../Componets/About/AboutSection'
import AboutCard from '../Componets/Home/AboutCard'
import Gallery from '../Componets/About/Gallery'
import Subscribe from '../Componets/Subscribe'
import Loading from '../Componets/Loading'
function AboutSingle() {
  return (
    <div className='container About'>
      <div className='nav'>
        <Navbar />
      </div>
      <Loading />
      <Banner heading={Data.heading} image={Data.image} />
      <AboutSecton />
      <AboutCard />
      <Gallery />
      <Subscribe />
      <Footer />
    </div>
  )
}

export default AboutSingle
