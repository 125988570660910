import React from 'react'

function TeamCard({item}) {
  return (
    <div className='card'>
        <div className='wrapper'>
            <div className='image'>
                <div className='circle'>
                    <img src={process.env.PUBLIC_URL + "/Assets/" + item.image} />
                </div>
                </div>
                <div className='details'>
                    <h3>{item.name}</h3>
                    <p>{item.proffesion}</p>
                    <p>{item.qualification}</p>
                </div>
        
        </div>
    </div>
  )
}

export default TeamCard
