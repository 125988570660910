import React from 'react'

function Spiner() {
  return (
    <div>
        Loading
    </div>
  )
}

export default Spiner
