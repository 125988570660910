import React from 'react'

function ErrorMessage({Message}) {
  return (
    <div className='ErrorMessage'>
        {Message}
    </div>
  )
}

export default ErrorMessage
