import React, { useState } from 'react'
import { FaPaperPlane, FaRegNewspaper } from 'react-icons/fa'
import Data from '../StaticData/Subscribe.json'
import Button from './Button'
import SucessfullyPopup from './SucessfullyPopup'
import emailjs from '@emailjs/browser';
import SendEmail from './SendEmail'

function Subscribe({page}) {
  const [open , setopen] = useState(false);
  const [email  , setEmail] = useState("");
  const submitForm = (e) => {
    e.preventDefault();
    SendEmail(email , "For Subscribe News Letters" , "Email is " + email)
    setEmail("");
    setopen(true);
  }

  return (
    <div className='subscribe-container container-center'>
      <SucessfullyPopup title={"Subscribed"} description={"now you got over latest news...!"} open={open} setopen={setopen} />
      <div className='bg-gradient'>
        <div className='heading'>
            <FaRegNewspaper /> {Data.heading}
        </div>
        <div className='Form'>
            <form onSubmit={submitForm}>
                <div className='fields-input'>
                  <FaPaperPlane /> <input type="text" value={email} placeholder='Email Address' onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className='fields-button'>
                 <Button>Subscribe</Button>
                </div>
            </form>
        </div>
      </div>
    </div>
  )
}

export default Subscribe
