import React from 'react'
import Data from '../../StaticData/About/About.json'
function AboutSection() {
  return (
    <div className='about-container container-center'>
        <h3>{Data.title}</h3>
        <p>{Data.Description}</p>
    </div>
  )
}

export default AboutSection
