import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from './Button'

function CourseCard({item}) {
  const navigation = useNavigate();
  return (
    <div className='card'>
      <div className='wrapper'>
        <div className='image'>
            <img src={process.env.PUBLIC_URL + '/Assets/' + item.image} />
        </div>
        <div className='details'>
          <h3>{item.title}</h3>
          <p dangerouslySetInnerHTML={{__html: item.description.substring(0 , 100) + "...."}}></p>
          <span>Duration: {item.duration}</span>
          <div className='Btn-Price'>
            <Button onClick={() => navigation('/course/signle?id=' + item.id)}>View Details</Button>
            <span className='price'>PKR {item.Amount}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseCard
