import React from 'react'

function Banner({image , heading}) {

  return (
    <div className='banner-container'>
        <div className='banner-image' style={{backgroundImage: "url(/Assets/"+ image +")"}}>
            <h3>{heading}</h3>
        </div>
    </div>
  )
}


export default Banner
