import React from 'react'
import HeroSection from '../Componets/Home/HeroSection'
import Navbar from '../Componets/Layout/Navbar'
import AboutCards from '../Componets/Home/AboutCard'
import About from '../Componets/Home/About'
import Progress from '../Componets/Home/Progress'
import Courses from '../Componets/Home/Courses'
import Blogs from '../Componets/Home/Blogs'
import Subscribe from '../Componets/Subscribe'
import Footer from '../Componets/Layout/Footer'
import Loading from '../Componets/Loading'
function Home() {
    return (
        <div className='container'>
            <div className='nav'>
                <Navbar />
            </div>
            <Loading />
            <HeroSection />
            <AboutCards />
            <About />
            <Progress />
            <Courses />
            <Blogs />
            <Subscribe />
            <Footer />
        </div>
    )
}

export default Home
