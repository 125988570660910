import { FaFacebook, FaLocationArrow, FaPhone, FaTwitter, FaWhatsapp } from "react-icons/fa"

const data = {
    "about": {
        "title": "Easy Tech",
        "description": "The purpose of this type of website is to uphold the overall brand of the instituate. A it education website contains a great deal of important information for students, faculty and staff. Additionally, it’s a way of representing the campus culture for prospective students."
    },
    "Links": [
        {
            "id": 0,
            "heading": "Main Links",
            "link": [
                {"name": "Home" , "url": '/'},
                {"name": "Courses" , "url": '/Courses'},
                // {"name": "Carrer" , "url": '/Carrer'},
                {"name": "Team" , "url": '/Team'}
            ]
        },
        {
            "id": 1,
            "heading": "Contact",
            "link": [
                {"name": "Addmission" , "url": '/Admission'},
                {"name": "Contact us" , "url": '/contactus'}
            ]
        },
        {
            "id": 1,
            "heading": "Information",
            "link": [
                {"name": "Wisdom School In Makli Hasmabad Society Makli" , "url": '/' , icon: <FaLocationArrow />},
                {"name": "+92 314 3111554" , "url": '/' , icon: <FaPhone />}
            ]
        }
    ],
    "down_footer":{
        "copyright": "Copyright @ Easy Texh 2022",
        "social": [
            {"icon" : <FaWhatsapp /> , "url": "/"},
            {"icon" : <FaFacebook /> , "url": "/"},
            {"icon" : <FaTwitter /> , "url": "/"},
        ]
    } 
}

export default data