import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from './Button'
import Empty from './Empty'
import Footer from './Layout/Footer'
import Navbar from './Layout/Navbar'
import Loading from './Loading'

function NotFound() {
    const navigation = useNavigate();
  return (
    <div className='container'>
            <div className='nav'>
                <Navbar />
            </div>
            <Loading />
            <div className='notFound-container container-center'>
                <img src={process.env.PUBLIC_URL + "/Assets/404.png"} />
                <p>We Could Find Page..?</p>
                <Button onClick={() => navigation("/")}>Go Back</Button>
            </div>
            <Footer />
    </div>
  )
}

export default NotFound
