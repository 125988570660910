import React from 'react'

function AboutCard({ item }) {
  return (
    <div className='card' key={item.id}>
      <div className='wrapper'>
        <div className='icon'>
          {item.icon}
        </div>
        <div className='Details'>
          <h4>{item.title}</h4>
          <p>{item.description}</p>
        </div>
      </div>
    </div>
  )
}

export default AboutCard
