import React, { useState } from 'react'
import { Link, useLocation   , redirect, useNavigate} from 'react-router-dom'
import Button from '../Button'
import { FaBars, FaTimes } from 'react-icons/fa'



function Navbar() {
    const [NavbarActive , setNavbarActive] = useState("");
    const location = useLocation();
    const navigation = useNavigate();

    return (
        <div className='navbar-container'>
            <div className='center'>
                <div className='row align big-screen-navbar'>
                    <div className='logo-col'>
                        <img src={'/Assets/EasyTechLogo.jpg'} style={{width: "100px" , height: '100px'}} />
                    </div>
                    <div className='links-col'>
                        <ul>
                            <li>
                                <Link to="/" className={(location.pathname === "/") ? 'ActiveLink' : "" }>Home</Link>
                            </li>
                            <li>
                                <Link to="/Courses" className={(location.pathname === "/Courses") ? "ActiveLink" : ""}>Courses</Link>
                            </li>
                                {/* <li>
                                    <Link to="/Carrer" className={(location.pathname === "/Carrer") ? "ActiveLink" : ""}>Carrer</Link>
                                </li> */}
                            <li>
                                <Link to="/Team" className={(location.pathname === "/Team") ? "ActiveLink" : ""}>Team</Link>
                            </li>
                            <li>
                                <Link to="/Admission" className={(location.pathname === "/Admission") ? "ActiveLink" : ""}>Apply for Addmission</Link>
                            </li>
                            <li>
                                <Link to="/Blogs" className={(location.pathname === "/Blogs") ? "ActiveLink" : ""}>Blogs</Link>
                            </li>
                            <li>
                                <Button onClick={() => navigation('/contactus')}>Contact us</Button>
                            </li>
                        </ul>
                    </div>
                    <div className='menu-col'>
                        <FaBars className={`menu ${NavbarActive}`} onClick={() => setNavbarActive("Active")} />
                        <FaTimes className={`close ${NavbarActive}`} onClick={() => setNavbarActive("")} />
                    </div>
                </div>



                <div className={`small-screen-navbar ${NavbarActive}`}>
                    <ul>
                        <li>
                            <Link to="/" className={(location.pathname === "/") ? 'ActiveLink' : "" }>Home</Link>
                        </li>
                        <li>
                            <Link to="/Courses" className={(location.pathname === "/Courses") ? 'ActiveLink' : "" }>Courses</Link>
                        </li>
                        <li>
                            <Link to="/Carrer"  className={(location.pathname === "/Carrer") ? 'ActiveLink' : "" }>Carrer</Link>
                        </li>
                        <li>
                            <Link to="/Team"  className={(location.pathname === "/Team") ? 'ActiveLink' : "" }>Team</Link>
                        </li>
                        <li>
                            <Link to="/Admission"  className={(location.pathname === "/Admission") ? 'ActiveLink' : "" }>Admission</Link>
                        </li>
                        <li>
                            <Link to="/Blogs"  className={(location.pathname === "/Blogs") ? 'ActiveLink' : "" }>Blogs</Link>
                        </li>
                        <li>
                            <Button onClick={() => navigation('/contactus')}>Contact us</Button>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    )
}

export default Navbar
