import React from 'react'
import { Link } from 'react-router-dom'
import data from '../../StaticData/Footer'
function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-wrapper container-center'>
        <div className='row'>
          <div className='About'>
          <img src={'/Assets/EasyTechLogo.jpg'} style={{width: "100px" , height: '100px' , borderRadius: '10px'}} />
            <p>{data.about.description}</p>
          </div>
          {
            data.Links.map((item) => (
              <div className='link' key={item.id}>
                <h3>{item.heading}</h3>
                <ul>
                  {
                    item.link.map((items) => (
                      <li>
                        <Link to={items.url}>{items?.icon} {items.name}</Link>
                      </li>
                    ))
                  }
                </ul>
              </div>
            ))
          }
        </div>
        <div className='footer-things'>
          <div className='copyright'>
            {
              data.down_footer.copyright
            }
          </div>
          <div className='Social-Links'>
            {
              data.down_footer.social.map((item) => (
                <Link to={item.url}>{item.icon}</Link>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
