import React from 'react'
import { useLocation } from 'react-router-dom'
import CourseBanner from '../Componets/CourseBanner'
import AboutCourse from '../Componets/Courses/AboutCourse'
import Footer from '../Componets/Layout/Footer'
import Navbar from '../Componets/Layout/Navbar'
import Data from '../StaticData/Courses/Course.json'
import TeacherData from '../StaticData/Team/Team.json'
import Subscribe from '../Componets/Subscribe'
import Loading from '../Componets/Loading'
import Empty from '../Componets/Empty'
function SingleCourse() {

    const location = useLocation();

    const filterdCardData = Data.Cards.filter(x => x.id === Number(location.search.split("=")[1]));
    const filtedTeacherData = TeacherData.cards.filter(x => x.id === Number(filterdCardData[0]?.teacher_id))

    return (
        <div className='container'>
            <div className='nav'>
                <Navbar />
            </div>
            <Loading />
            
            {
                (filterdCardData.length > 0) ?
                    <>
                        <CourseBanner Data={Data} teacher_data={filtedTeacherData} CardData={filterdCardData} />
                        <AboutCourse CardData={filterdCardData} />
                    </>
                    :
                    <><div className='spacer'></div><Empty title={"Sorry We Could Not Find This Course...!"} /></>
            }

            <Subscribe />
            <Footer />
        </div>
    )
}

export default SingleCourse
