import React from 'react'
import Banner from '../Componets/Banner'
import CardGrid from '../Componets/Carrer/card-grid'
import Footer from '../Componets/Layout/Footer'
import Navbar from '../Componets/Layout/Navbar'
import Loading from '../Componets/Loading'
import Subscribe from '../Componets/Subscribe'
import Data from '../StaticData/Carrer/Carrer.json'
function Carrer() {
    return (
        <div className='container'>
            <div className='nav'>
                <Navbar />
            </div>
            <Loading />
            <Banner heading={Data.heading} image={Data.banner} />    
            <CardGrid Data={Data} />
            <Subscribe />
            <Footer />
        </div>
    )
}

export default Carrer
