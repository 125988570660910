import React, { useState } from 'react'
import Banner from '../Componets/Banner'
import ContactForm from '../Componets/Contact/ContactForm'
import Footer from '../Componets/Layout/Footer'
import Navbar from '../Componets/Layout/Navbar'
import Loading from '../Componets/Loading'
import Subscribe from '../Componets/Subscribe'
import SucessfullyPopup from '../Componets/SucessfullyPopup'
import Data from '../StaticData/Contact/Contact.json'
function ContactUs() {
    const [open , setopen] = useState(false);
    return (
        <div className='container'>
            <div className='nav'>
                <Navbar />
            </div>
            <Loading />
            <SucessfullyPopup title={"Success"} description="We Will Contact You Via Email. We'll see you soon!" open={open} setopen={setopen} />
            <Banner heading={Data.heading} image={Data.image} />
            <ContactForm Data={Data} setopen={setopen} />
            <Subscribe />
            <Footer />
        </div>
    )
}

export default ContactUs
