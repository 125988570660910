function SendEmailFile(Subject , Body , base64String , file , Email) {
    const config = {
        Host: process.env.REACT_APP_EMAIL_HOST,
        Port: process.env.REACT_APP_EMAIL_PORT,
        Username: process.env.REACT_APP_EMAIL_USER_NAME,
        Password: process.env.REACT_APP_EMAIL_PASSWORD,
        To: process.env.REACT_APP_EMAIL_TO,
        From: Email,
        Subject: Subject,
        Body: Body,
        Attachments: [ {
                name: file.name,
                data: base64String
         }]
    }
    window.Email.send(config);
}

export default SendEmailFile
