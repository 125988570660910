import React from 'react'
import BlogCard from '../BlogCard'
import Empty from '../Empty'
function CardGrid({Data}) {
  return (
    <div className='cards-container'>
        <div className='card-container'>
            {
               Data.map((item) => (
                <BlogCard item={item} />
               ))
            }

            {
              (Data.length > 0) ? "" : 
              <Empty title="There have no any Blog..!" />
            }
        </div>
    </div>
  )
}

export default CardGrid
