import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import Data from '../../StaticData/Home/Progress.json'
function Progress() {

    const { inView, ref } = useInView({
        threshold: 0
    })


    useEffect(() => {
        if (inView) {
            var circle = document.querySelectorAll('circle');

            for (let i = 0; i < circle.length; i++) {
                var radius = circle[i].r.baseVal.value;
                var circumference = radius * 2 * Math.PI;

                circle[i].style.strokeDasharray = `${circumference} ${circumference}`;
                circle[i].style.strokeDashoffset = `${circumference}`;
                let value = 0;
                let endvalue = Data.progress_bar[i].progress;
                let progress = setInterval(() => {
                    value++;
                    const offset = circumference - value / 100 * circumference;
                    circle[i].style.strokeDashoffset = offset;
                    if(value == endvalue){
                        clearInterval(progress);
                    }
                })
            }

        }
    }, [inView])

    return (
        <div ref={ref} className='progress-container container-center'>
            <div className='grad-bg'>
                <p>{Data.heading}</p>
                <div className='progress-row'>

                    {
                        Data.progress_bar.map((item, index) => (
                            <div className='progress-card'>
                                <div className='skill'>
                                    <div className='outer'>
                                        <div className='inner'>
                                            <div id="number">{ item.value }</div>
                                        </div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
                                    <defs>
                                        <linearGradient id="GradientColor">
                                            <stop offset="0%" stop-color="#e91e63" />
                                            <stop offset="100%" stop-color="#673ab7" />
                                        </linearGradient>
                                    </defs>
                                    <circle cx="80" cy="80" r="70" stroke-linecap="round" />
                                </svg>
                                <div className="title">
                                    {item.name}    
                                </div>
                                </div>
                              
                            </div>
                        ))
                    }

                </div>
            </div>
        </div>
    )
}

export default Progress
