import React from 'react'

function AboutCourse({CardData}) {
  return (
    <div className='AboutCourse-container container-center'>
        <h3>About Course</h3>
        <p dangerouslySetInnerHTML={{__html:CardData[0].description }}>
        </p>
        <div className='Gallery'>
            <h3>Gallery</h3>
            <div className='Gallery-row'>
                {
                    CardData[0].Gallery.map((item) => (
                        <div className='Gallery-image'>
                            <img src={process.env.PUBLIC_URL + '/Assets/'+ item} />
                         </div>
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default AboutCourse
