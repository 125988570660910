import React from 'react'
import { FaUsers } from 'react-icons/fa'
import TeamCard from '../TeamCard'

function cardGrid({Data}) {
  return (
    <div className='team-container'>
        <div className='wrapper container-center'>
            <div className='heading'>
                <FaUsers /> {Data.title}
            </div>
            <div className='card-container'>
                {
                    Data.cards.map((item) => (
                        <TeamCard item={item} />
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default cardGrid
