import React, { useState } from 'react'
import { FaBookmark, FaFilter, FaSearch } from 'react-icons/fa'
import Data from '../../StaticData/Courses/Course.json'
import Button from '../Button'

function FilterSearchTitle({FilterBtn , setsearchData , FilterBtnOnChange , ClearEffect}) {
  const [open , setOpen] = useState("");

  return (
    <div className="filter-container container-center">
      <div className='wrapper'>
        <div className='heading'>
          <FaBookmark /> {Data.heading}
        </div>
        <div className='search'>
          <input type="text" placeholder='Search Course' onChange={(e) => setsearchData(e.target.value)} /> <FaSearch />
        </div>
        <div className="filter ">
          <Button onClick={() => (open === "active") ? setOpen("") : setOpen("active")}><FaFilter />Filter</Button>
          <div className={'Filter-btn ' + open}>

            <div className='field'>
              <h3>Sort</h3>
              <div className='fields'>
                  <input type="radio" onChange={FilterBtnOnChange} name="ASC" checked={FilterBtn.ASC} />
                  <label>ASC</label>
              </div>
              <div className='fields'>
                  <input type="radio" onChange={FilterBtnOnChange} name="DESC" checked={FilterBtn.DESC} />
                  <label>DESC</label>
              </div>
            </div>
            <div className='field'>
              <h3>Price</h3>
              <div className='fields'>
                  <input type="radio" onChange={FilterBtnOnChange} name="LOWEST"  checked={FilterBtn.LOWEST} />
                  <label>Lowest</label>
              </div>
              <div className='fields'>
                  <input type="radio" onChange={FilterBtnOnChange} name="HIGHEST" checked={FilterBtn.HIGHEST} />
                  <label>Highest</label>
              </div>
              <div className='fields btns'>
                <Button onClick={() => ClearEffect()}>Clear</Button>
                <Button onClick={() => setOpen("")}>Close</Button>
              </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default FilterSearchTitle
