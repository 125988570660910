import React from 'react'
import HerosectionData from '../../StaticData/Home/HeroSection.json'
import Button from '../Button'
import { useNavigate } from 'react-router-dom'
function HeroSection() {
  const Navigation = useNavigate();
  return (
    <div className='herosection-container'>
        <div className='Details center'>
            <h3>
                {HerosectionData.Title}
            </h3>
            <div className='DescriptionBox'>
                <p>{HerosectionData.Description}</p>
            </div>
            <Button onClick={() => Navigation('/contactus')}>Contact us</Button>
        </div>
        {/* <div className='bg-dark-image'></div> */}
    </div>
  )
}

export default HeroSection
