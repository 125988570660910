import React from 'react'
import { FaSearch } from 'react-icons/fa'


function Tools({ Data , FilterData, textonChange , TagsonChange , categouresonChange }) {
    return (
        <div className='tools-container'>
            <div className='Search'>
                <span>Search</span>
                <div className='input'>
                    <input type="text" onChange={textonChange} placeholder='Search Blogs' /> <FaSearch />
                </div>
            </div>
            <div className='categerous'>
                <span>Categories</span>
                <ul>
                    {
                        Data.categerous.map((item) => (
                            <li className={(FilterData.categerous === item) ? "active" : ""} key={item} onClick={() => categouresonChange(item)}>{item}</li>
                        ))
                    }
                </ul>
            </div>
            <div className='RecentPost'>
            <span>Recent Posts</span>
                {
                    Data.Cards.map((item) => (
                        (item.RecentPost === true) && (
                            <div className='card'>
                                <div className="image">
                                    <img src={process.env.PUBLIC_URL + '/Assets/' + item.image} />
                                </div>  
                                <div className="description">
                                    <p>{item.description.substring(0 , 60)}</p>
                                </div>
                            </div>
                        )
                    ))
                }
            </div>
            <div className='tags'>
                {
                    Data.tags.map((item) => (
                        <li className={(FilterData.tags === item) ? "active" : ""} onClick={() => TagsonChange(item)}>{item}</li>
                    ))
                }
            </div>
        </div>
    )
}

export default Tools
