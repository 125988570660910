import React from 'react'
import { FaCalendar } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import Button from './Button'

function BlogCard({item}) {
    const navigation = useNavigate();
  return (
    <div className='card'>
        <div className='wrapper'>
            <div className='image'>
                <img  src={process.env.PUBLIC_URL + '/Assets/' + item.image} />
            </div>
            <div className='details'>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <div className='btn-date'>
                    <div className='Date'>
                        <FaCalendar /> Data: {item.date}
                    </div>
                    <Button onClick={() => navigation("/Blogs/single?id="+ item.id)}>Read More</Button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BlogCard
