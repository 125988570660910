import React from 'react'

function SingleAbout({card_data}) {
  return (
    <div className='blog-about-container container-center'>
    
      <h3>{card_data[0].title}</h3>
      <p>{card_data[0].description}</p>

      <div className='image'>
        <img src={process.env.PUBLIC_URL + "/Assets/" + card_data[0].image} />
      </div>
    </div>
  )
}

export default SingleAbout
